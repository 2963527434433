
import { Options, Vue } from "vue-class-component";
import PersonDetails from "@/components/PersonDetails.vue";
import CourseInput from "@/components/CourseInput.vue";
import DatePicker from "@/components/Form/DatePicker.vue";
import TextInput from "@/components/Form/TextInput.vue";
import MandatoryFieldFlag from "@/components/Form/MandatoryFieldFlag.vue";
import StudentService from "@/services/student-service";
import DetailsHeader from "@/components/DetailsHeader.vue";
import { Course, Student } from "@/interfaces/api";
import { PersonCourse } from "@/interfaces/PersonCourse";
import { Constants } from "@/constants/constants";
import DateHelper from "@/helpers/date-helper";
import TermHelper from "@/helpers/term-helper";
import Accordion from "@/components/Accordion.vue";
import CourseService from "@/services/course-service";

@Options({
  components: {
    TextInput,
    CourseInput,
    DatePicker,
    PersonDetails,
    MandatoryFieldFlag,
    DetailsHeader,
    Accordion,
  },
  data: () => ({
    /*
     * A boolean indicating if the student has been saved
     */
    studentIsSaved: false,
    /*
     * Holds a student response object containing the student data and also a boolean value representing whether or not
     * the student already exists in the STP database. It is populated by a call to the student service
     */
    personResponse: null,
    /*
     * Holds the personal id entered in the text input
     */
    personalId: null,
    /*
     * Save call to service returns a boolean value indicating whether the save was successful or not
     * Used to display the result to the user
     */
    saveWasSuccessful: null,
    /**
     * The maximum number of characters the user can enter into the input
     */
    maxLength: 13,
    /**
     * A regular expression the input's value should match
     *   - A sequence of up to 12 digits
     *   - A sequence of up to 8 digits followed by an optional '+' or '-' and up to 4 digits
     *   - A sequence of up to 8 digits followed by an optional '+' or '-' and a single letter followed by up to 3 digits.
     */
    pattern:
      "^([0-9]{0,12}|[0-9]{0,8}[-+]?[0-9]{0,4}|[0-9]{0,8}[-+]?[A-Z, a-z]{1}[0-9]{0,3})$",
    /**
     * Copy of student courses with added attribute, "checked"
     */
    coursesOption: [],
    /**
     * Required if showCoordinator
     * The functionId to fetch coordinators from
     */
    functionId: Constants.NoteTakingFunctionId,
    /*
     * Disables selection of past dates in the date picker
     */
    disabledDates(date: Date): boolean {
      return date < new Date();
    },
    /**
     * Populated with student courses which have a start date later than the granted support date
     */
    coursesWithLaterStartDate: new Array<PersonCourse>(),
  }),
  computed: {
    showStudentDetails(): boolean {
      return (
        !this.studentIsSaved &&
        this.personResponse &&
        !this.personResponse.studentExists &&
        this.personResponse.student &&
        !this.personResponse.isActiveNoteTaker
      );
    },
    /*
     * The placeholder for the text input
     */
    placeholder(): string {
      return `${this.$t("add-student.personalIdFormat")}: 20001101-0112`;
    },
    /*
     * Keeps the fetch button disabled until a full 12 number personal id is entered
     */
    fetchButtonIsDisabled(): boolean {
      return this.personalId
        ? this.personalId.length < this.maxLength - 1
        : true;
    },
    /*
     * Selected courses
     */
    selectedCourses(): Array<PersonCourse> {
      const selectedCourses = this.coursesOption.filter(
        (course: PersonCourse) => course.checked
      );
      return selectedCourses;
    },
    /*
     * Keeps the save button disabled until a non existing student is fetched, courses is selected, student not saved
     */
    saveButtonIsDisabled(): boolean {
      return (
        !this.personResponse ||
        this.personResponse.studentExists ||
        !this.personResponse.student ||
        this.selectedCourses.length <= 0 ||
        !this.personResponse.student.grantedSupportDate ||
        this.saveWasSuccessful ||
        !this.personResponse.student.coordinatorId ||
        this.hasLaterStartDates
      );
    },
    /*
     * The message displayed in the GUI if a save is successful
     */
    saveWasSuccessfulMsg(): string {
      const student = this.personResponse.student;
      const msg = this.$t("add-student.saveSuccessful", {
        student_name: `${student.firstName} ${student.lastName}`,
      });
      return msg;
    },
    /**
     * Gets the terms
     * Eg: ['VT22', 'HT22']
     */
    terms(): string[] {
      return TermHelper.getTerms(this.coursesOption);
    },
    /**
     * The date that the student is granted support until
     */
    grantedSupportDate(): string {
      return this.personResponse && this.personResponse.student
        ? this.personResponse.student.grantedSupportDate
        : null;
    },
    /**
     * Indicates if the granted support date is earlier than any of the selected course start dates
     */
    hasLaterStartDates(): boolean {
      return this.coursesWithLaterStartDate.length > 0;
    },
    /**
     * The text displayed to the user if the granted support date is earlier than any of the selected course start dates
     */
    laterStartDateText(): string {
      return this.$t("add-student.later-start-date");
    },
  },
  methods: {
    /*
     * Calls the student service to fetch data for the student with the specified personal id and also
     * a boolean value indicating whether or not the student already exists in the STP database
     */
    async fetchStudent(): Promise<void> {
      this.studentIsSaved = false;
      if (!this.fetchButtonIsDisabled) {
        this.saveWasSuccessful = null;
        this.personResponse =
          await StudentService.getStudentResponseByPersonalId(
            this.personalId.replace(/-/g, "")
          );
        if (this.personResponse.student) {
          this.coursesOption = this.personResponse.student.courses.map(
            (course: Course) => {
              return {
                ...course,
                checked: this.isCompletedCourse(course.courseUid)
                  ? false
                  : true,
              };
            }
          );
        }
      }
    },
    /*
     * Calls the student service to save the student with the specified personal id in the STP database
     * Service call returns a boolean value indicating whether the save was successful or not
     */
    onSaveClicked(): void {
      this.$store.commit("showSpinner", true);
      this.personResponse.student.supportCourses = this.selectedCourses
        .filter((course: PersonCourse) => course.checked)
        .map((course: PersonCourse) => ({
          courseUid: course.courseUid,
        }));

      StudentService.addOrUpdateStudent(this.personResponse.student)
        .then((student: Student) => {
          this.addedStudent = student;
          this.saveWasSuccessful = true;
          this.focusTextInput();
          this.studentIsSaved = true;
        })
        .catch((e: Error) => {
          this.saveWasSuccessful = false;
          console.error(e);
        })
        .finally(() => {
          this.$store.commit("showSpinner", false);
        });
    },
    /*
     * Resets data properties to null which hides all but the text input and fetch button
     */
    onCancelClicked(): void {
      this.personResponse = null;
      this.personalId = null;
      this.saveWasSuccessful = null;
      this.coursesWithLaterStartDate = new Array<PersonCourse>();
      this.hasLaterStartDates = false;
      this.focusTextInput();
    },
    /*
     * Places focus in the text input
     */
    focusTextInput(): void {
      this.$refs.personalIdInput?.focus();
    },

    navigateToOverview() {
      this.$router.push({
        name: "StudentList",
      });
    },
    /**
     * Get courses within a term
     *
     * @param {string} term The term
     * @returns {PersonCourse[]} The courses within the term
     */
    getCoursesByTerm(term: string): PersonCourse[] {
      return TermHelper.getCoursesByTerm(this.coursesOption, term);
    },
    getTermString(term: string): string {
      return TermHelper.getTermString(term, this.$t);
    },
    /**
     * The message displayed in the GUI if the person's name is to be used
     */
    showNameMsg(localeKey: string): string {
      const person = this.personResponse.student;
      const msg = this.$t(localeKey, {
        full_name: `${person.firstName} ${person.lastName}`,
      });
      return msg;
    },
    /**
     * Checks if the granted support date is earlier than any of the selected course start dates
     */
    checkGrantedSupportDate(): void {
      if (this.grantedSupportDate) {
        this.coursesWithLaterStartDate = this.selectedCourses.filter(
          (course: PersonCourse) =>
            DateHelper.dateAisBeforeDateB(
              this.grantedSupportDate,
              course.courseStartDate
            )
        );
      }
    },
    /**
     * Checks if the specified course has a later date than the granted support date
     * Used to highlight the course in the GUI
     */
    isCourseWithLaterStartDate(courseUid: string): boolean {
      return (
        this.coursesWithLaterStartDate.find(
          (x: PersonCourse) => x.courseUid === courseUid
        ) !== undefined
      );
    },
    /**
     * Checks whether the course with the specified course Uid has an end date that has passed
     * Used for setting the disabled attribute on the course checkbox
     *
     * @param {string} courseUid The course Uid
     * @returns {boolean} whether the course has an end date that has passed
     */
    isCompletedCourse(courseUid: string): boolean {
      return CourseService.isCompletedCourse(
        courseUid,
        this.personResponse.student.courses
      );
    },
    /**
     * Regulates the coursesWithLaterStartDate array
     */
    onCourseCheckedChanged(course: PersonCourse): void {
      const index = this.coursesWithLaterStartDate.indexOf(course);

      if (index > -1) {
        this.coursesWithLaterStartDate.splice(index, 1);
      }

      this.checkGrantedSupportDate();
    },
  },
  watch: {
    grantedSupportDate() {
      this.checkGrantedSupportDate();
    },
  },
  mounted() {
    this.focusTextInput();
  },
  unmounted() {
    this.studentResponse = null;
  },
})
export default class AddStudent extends Vue {}
